import React from "react";
import "./FAQ.css";
import FAQAccordian from "../../components/faq/FAQAccordian/FAQAccordian";
import SpaceWrapper from "../../components/wrapper/SpaceWrapper/SpaceWrapper";
import AskTaaraForm from "../../components/AskTaaraForm/AskTaaraForm";
import faqs from "../../data/faqs.json";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import Header from "../../components/Header/Header";

const FAQ = () => {
  return (
    <NonProtectedLayout>
      <Header
        title="Taara Quest FAQ: Your Guide to AI-Powered Roleplay for Growth"
        description="Taara Quest FAQ: Your Guide to AI-Powered Roleplay for Growth"
        keywords="ai learning tools, taara frequently asked questions"
      />
      <SpaceWrapper>
        <h1 className="faq-heading">WHAT THE FAQ?</h1>
        <h3 className="faq-sub-heading mt-4">
          Let’s get those burning questions out of the way!
        </h3>
        <div className="faq-container">
          <div className="faq-container-left">
            <h3 className="heading">Shortcut To Your Question</h3>
            <div className="faq-questions ">
              {faqs.map((faq, idx) => (
                <div className="question" key={idx}>
                  {faq.question}
                </div>
              ))}
            </div>
          </div>
          <div className="faq-container-right">
            {faqs.map((faq, idx) => (
              <FAQAccordian
                key={idx}
                question={faq.question}
                answer={faq.answer}
              />
            ))}
          </div>
        </div>
      </SpaceWrapper>
      <AskTaaraForm />
    </NonProtectedLayout>
  );
};

export default FAQ;
