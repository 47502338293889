import React, { useCallback, useEffect, useMemo, useState } from "react";
import Footer from "../../components/Footer/Footer";
import "./Blog.css";
import SpaceWrapper from "../../components/wrapper/SpaceWrapper/SpaceWrapper";
import FilterTag from "../../components/blog/FilterTag/FilterTag";
import BlogCard from "../../components/blog/BlogCard/BlogCard";
import filterIcon from "../../images/filterIcon.svg";
import FiltersModal from "../../components/blog/FiltersModal/FiltersModal";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import blogs from "../../data/blogs";
import LazyImageObjectFit from "../../components/LazyImage/LazyImageObjectFit";
import Header from "../../components/Header/Header";

const filterTags = [
  "Confidence",
  "Workplace Behaviour",
  "Difficult Conversations",
  "Leadership",
];

const Blog = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [allBlogs, setAllBlogs] = useState(blogs);
  const handleOpenModal = () => setOpenModal(true);
  const closeModal = () => setOpenModal(false);

  const handleToggleFilter = (filterOption) => {
    const currentFilters = [...selectedFilters];
    if (selectedFilters.includes(filterOption)) {
      const filterIndex = currentFilters.indexOf(filterOption);
      currentFilters.splice(filterIndex, 1);
      setSelectedFilters(currentFilters);
    } else {
      setSelectedFilters((prev) => [...prev, filterOption]);
    }
  };

  const filterBlogs = useCallback(() => {
    if (selectedFilters.length === 0) {
      setAllBlogs(blogs);
    } else {
      const filteredBlogs = blogs.filter((blog) =>
        selectedFilters.some((filter) => blog.tags.includes(filter))
      );
      setAllBlogs(filteredBlogs); // Show blogs that match any of the selected filters
    }
  }, [selectedFilters]);

  useEffect(() => {
    filterBlogs();
  }, [selectedFilters]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleMobileFilters = (filters) => {
    setSelectedFilters(filters);
    closeModal();
  };

  return (
    <NonProtectedLayout showFooter={false}>
      <Header
        title="How to Build Confidence and Thrive with AI Tools | Taara Quest Blog"
        description="How to Build Confidence and Thrive with AI Tools | Taara Quest Blog"
        keywords="ai learning tools, taara blog"
      />
      <div className="blog-container">
        <SpaceWrapper>
          <div className="blog-content">
            <div className="blog-content-left flex flex-col gap-6 md:gap-8">
              <div className="small-heading">Featured Post</div>
              <h3>Building Confidence</h3>
              <div className="flex flex-col gap-4">
                <p>
                  Women are still fighting for equality in the working world
                  today. Though much has been accomplished, for many, the
                  proverbial "glass ceiling" still exists when trying to find a
                  place in leadership.
                </p>
                <p>
                  Confidence learned by that woman working in this field may
                  someday prove the biggest step over those barriers and into
                  shattering that invisible glass. Be it promotion or making...
                </p>
              </div>
            </div>

            <div className="blog-content-right">
              <div className="small-heading-mobile">Featured Post</div>
              <div className="post-image">
                <LazyImageObjectFit
                  src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/blogs/building_confidence/Image+1.webp"
                  alt="Building Confidence"
                  className="w-full h-full object-cover rounded-[20px]"
                />
              </div>
            </div>
          </div>
          <div className="all-posts-container">
            <div className="all-posts-header">
              <div className="heading-container">
                <h3 className="all-posts-heading">All Posts</h3>
                <div className="filter" onClick={handleOpenModal}>
                  <img src={filterIcon} alt="filter-icon" />
                </div>
              </div>
              <div className="all-posts-tags">
                {filterTags.map((filterTag, idx) => (
                  <FilterTag
                    key={idx}
                    tag={filterTag}
                    isSelected={selectedFilters.includes(filterTag)}
                    onClick={() => handleToggleFilter(filterTag)}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="blog-list-container">
            {allBlogs.map((blog, idx) => (
              <BlogCard key={idx} blog={blog} />
            ))}
          </div>
          {/* <div className="show-more-container">
            <button className="show-more-button">Show more</button>
          </div> */}
        </SpaceWrapper>
        {openModal && (
          <FiltersModal
            filters={selectedFilters}
            filterTags={filterTags}
            closeModal={closeModal}
            handleMobileFilters={handleMobileFilters}
          />
        )}
        <Footer />
      </div>
    </NonProtectedLayout>
  );
};

export default Blog;
